<template>
    <div class="ji_qi_div">
        <div class="ji_qi_div_row" v-for="itemList, index in jiQiList" :key="index">
            <div class="ji_qi_div_row_item" v-for="jtemList, jndex in itemList.left" :key="jndex">
                <div class="ji_qi_div_row_item_left" :class="setBgColor(itemList.left[jndex].status)">
                    <div class="ji_qi_no_class">
                        {{ itemList.left[jndex].no }}
                    </div>
                    <div class="ji_qi_div_row_item_left_item">
                        <div class="ji_qi_div_row_item_left_item_row">
                            {{ itemList.left[jndex].currentChanLiangDouble }} |{{ itemList.left[jndex].currentXiaoLv }}%
                        </div>
                        <div class="ji_qi_div_row_item_left_item_row">
                            <span v-if="itemList.left[jndex].status == 0">空闲</span>
                            <span v-if="itemList.left[jndex].status == 1">运行</span>
                            <span v-if="itemList.left[jndex].status == 2">

                                <span
                                    v-if="itemList.left[jndex].alarmname != null && itemList.left[jndex].alarmname.length <= 6">{{
                                        itemList.left[jndex].alarmname
                                    }}</span>
                                <span
                                    v-if="itemList.left[jndex].alarmname != null && itemList.left[jndex].alarmname.length > 6">{{
                                        itemList.left[jndex].alarmname.substr(0, 6) }}...</span>

                            </span>
                        </div>
                    </div>
                    <!-- 效率低于85显示感叹号 -->
                    <div class="ji_qi_div_row_item_right_item">
                        <span v-if="itemList.left[jndex].currentXiaoLv < 85">!</span>
                    </div>
                </div>
                <div class="ji_qi_div_row_item_right" :class="setBgColor(itemList.right[jndex].status)">
                    <div class="ji_qi_no_class">
                        {{ itemList.right[jndex].no }}
                    </div>
                    <div class="ji_qi_div_row_item_left_item">
                        <div class="ji_qi_div_row_item_left_item_row">
                            {{ itemList.left[jndex].currentChanLiangDouble }} |{{ itemList.left[jndex].currentXiaoLv }}%
                        </div>
                        <div class="ji_qi_div_row_item_left_item_row">
                            <span v-if="itemList.right[jndex].status == 0">空闲</span>
                            <span v-if="itemList.right[jndex].status == 1">运行</span>
                            <span v-if="itemList.right[jndex].status == 2">

                                <span
                                    v-if="itemList.right[jndex].alarmname != null && itemList.right[jndex].alarmname.length <= 6">{{
                                        itemList.right[jndex].alarmname
                                    }}</span>
                                <span
                                    v-if="itemList.right[jndex].alarmname != null && itemList.right[jndex].alarmname.length > 6">{{
                                        itemList.right[jndex].alarmname.substr(0, 6) }}</span>

                            </span>
                        </div>
                    </div>
                    <div class="ji_qi_div_row_item_right_item">
                        <span v-if="itemList.left[jndex].currentXiaoLv < 85">!</span>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 63%;width: 20%;display: flex;justify-content: space-around;flex-wrap: wrap;">
            <div style="width:100%;height: 0.7rem;display: flex;justify-content: center;align-items: center;">
                <div style="width:50%;font-size: .0625rem;">产量最低</div>
                <div style="width:50%;font-size: .0625rem;">故障数最高</div>
            </div>
            <div style="display: flex;justify-content: space-around;width: 100%;" v-for="item, index in jiQiChanLiangMin10"
                :key="index">
                <div class="ji_qi_div_row_item_right"
                    style="background-color: #a0cfff;height: 0.7rem;justify-content: space-around;">
                    <div class="ji_qi_no_class" style="font-size: 0.3rem;">
                        {{ item.macno }}
                    </div>
                    <div class="ji_qi_no_class">
                        {{ jiQiChanLiangMin10[index].currentChanLiangDouble }}
                    </div>
                </div>
                <div class="ji_qi_div_row_item_right"
                    style="background-color: #ff9900;height: 0.7rem;justify-content: space-around;">
                    <div class="ji_qi_no_class" style="font-size: 0.3rem;">
                        {{ guZhangShuTop10[index].macno }}
                    </div>
                    <div class="ji_qi_no_class">
                        {{ guZhangShuTop10[index].baoJingCount }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        //机器列表
        jiQiList: [],
        //产量最低的10个
        jiQiChanLiangMin10: [],
        //故障数最高的10个
        guZhangShuTop10: []
    },
    data() {
        return {
        }
    }, created() {
        console.log(this.jiQiChanLiangMin10, "[[[[[]]]]]")
    }, methods: {
        setBgColor(e) {
            if (e == 0) {
                return 'kx_class';
            }
            if (e == 1) {
                return 'run_class';
            }
            if (e == 2) {
                return 'bj_class';
            }
            if (e == -2) {
                return 'gj_class';
            }
            if (e == -1) {
                return 'dx_class';
            }
        }
    }
}
</script>
<style scoped>
.ji_qi_div {
    margin-top: .225rem;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.ji_qi_div_row {
    width: 25%;
    height: 63%;
    /* border: 1px solid yellow; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.ji_qi_div_row_item {
    width: 100%;
    height: 0.70rem;
    font-size: 0.0625rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ji_qi_div_row_item_left {
    width: 45%;
    height: 91%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ji_qi_div_row_item_right {
    width: 45%;
    height: 91%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ji_qi_div_row_item_left_item {
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.ji_qi_div_row_item_right_item {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ji_qi_no_class {
    width: 30%;
    font-size: 0.22rem;
    font-weight: bold;
}

.ji_qi_div_row_item_left_item_row {
    width: 100%;
    height: 26%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .0625rem;
}

/* 运行背景色 */
.run_class {
    background-color: #0F8E3D;
}

.bj_class {
    background-color: #F04A49;
}

.kx_class {
    background-color: #BAA645;
}

.gj_class {
    background-color: #4C4C64;
}

/* 掉线 */
.dx_class {
    background-color: #9FA5C2;
}
</style>