<template>
    <div class="top_div_class">
        <div class="top_div_class_item"></div>
        <div class="top_div_class_item" style="color:#A57A76;   font-size: .675rem;">
            耐尔袜业-机器状态看板
        </div>
        <div class="top_div_class_item ">
            <div class="top_div_class_item_time">
                {{ currentTimeStr }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            currentTimeStr: null,//当前展示时间
        }
    },
    created() {
        this.showTime()
    }, methods: {
        //展示时间
        showTime() {
            var _this = this
            setInterval(function () {
                _this.currentTimeStr = _this.getCurrentDate()
            }, 1000)
        }
    }
}
</script>
<style scoped>
.top_div_class {
    height: 0.7875rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.top_div_class_item {
    width: 33%;
}

.top_div_class_item_time {
    color: #fff;
    font-size: .275rem;
    text-align: right;
    padding-right: .625rem;
}
</style>