<template>
    <dv-full-screen-container class="da_pin_container" allowfullscreen="true">
        <dv-loading v-if="showLoaing" style="color:#fff">数据加载中...</dv-loading>

        <!-- 头部 -->
        <Top />
        <!-- 班次等信息 -->
        <Title :banCiInfo="indexData" />
        <!-- 机器状态列表 -->
        <jiQiList :jiQiList="indexData.jiQiList" :jiQiChanLiangMin10="indexData.jiQiChanLiangMin10"
            :guZhangShuTop10="indexData.guZhangShuTop10" />
    </dv-full-screen-container>
</template>
<script>
import Top from './components/top.vue'
import Title from './components/title.vue'
import jiQiList from './components/jiQiList.vue'
export default {
    components: {
        jiQiList,
        Top,
        Title
    },
    data() {
        return {
            showLoaing: true,
            indexData: {}//统计数据
        }
    }, created() {
        this.getIndexData()
    }, methods: {
        //获取首页数据
        getIndexData() {
            var _this = this

            this.showLoaing = false
            _this.$axios.get('/index/indexData').then(res => {
                _this.indexData = res.data
                _this.showLoaing = false
            })
            setInterval(function () {
                _this.$axios.get('/index/indexData').then(res => {
                    _this.indexData = res.data
                    _this.showLoaing = false
                })
            }, 15000)
        }
    }
}
</script>
<style scoped>
.da_pin_container {
    background-color: #3D3D57;
}
</style>