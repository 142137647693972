<template>
    <div class="title_div_class">
        <div class="title_div_class_row">
            <div class="title_div_class_row_item" style="  width: 60%;">
                <div class="title_div_class_row_item_text">
                    车间数据统计：{{ banCiInfo.banCi }}
                </div>
                <div class="title_div_class_row_item_text" style="margin-left:15%">
                    当班效率：{{ banCiInfo.dangBanXiaoLv }}% 运转率：{{ banCiInfo.yunZhuanLv }}%
                </div>
            </div>
            <div class="title_div_class_row_item" style="  width: 40%;">
                <div class="title_div_class_row_item_text">
                    <div class="ji_qi_type_class">
                        <div class="crile_class" style="background: #429C5E">
                        </div> 运行：{{ banCiInfo.jiQiStatusCountVo.yunXingCount }}
                    </div>

                    <div class="ji_qi_type_class">
                        <div class="crile_class" style="background:#B7A342"></div>
                        待机：{{ banCiInfo.jiQiStatusCountVo.daiJiCount }}
                    </div>
                    <div class="ji_qi_type_class">
                        <div class="crile_class" style="background:#F64841"></div>
                        报警：{{ banCiInfo.jiQiStatusCountVo.baoJingCount }}
                    </div>
                    <div class="ji_qi_type_class">
                        <div class="crile_class" style="background:#9FA5C2"></div>
                        离线：{{ banCiInfo.jiQiStatusCountVo.liXIanCount }}
                    </div>
                    <div class="ji_qi_type_class">
                        <div class="crile_class" style="background:#4C4C64"></div>
                        关机：{{ banCiInfo.jiQiStatusCountVo.guanJiCount }}
                    </div>
                </div>
            </div>
        </div>
        <div class="title_div_class_row">
            <div class="title_div_class_row_item" style="color:#fff;width:60%">
                <div class="title_div_class_row_item_text">
                    当前产量：{{ banCiInfo.dangQianChanLiang }}双 <el-divider direction="vertical"></el-divider>
                    预计产量：{{ banCiInfo.yuJiChanLiang }}双
                    <el-divider direction="vertical"></el-divider>
                    目标产量：{{ banCiInfo.muBiaoChanLiang }}双
                    <el-divider direction="vertical"></el-divider>
                    总故障数：{{ banCiInfo.zongGuZhangCount }}
                </div>
            </div>
            <div class="title_div_class_row_item" style="color:#fff;width:40%">
                <div class="title_div_class_row_item_text">
                    感叹号：机器效率低于90%
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        banCiInfo: {}
    },
    data() {
        return {

        }
    }, created() {

    }, methods: {

    }
}
</script>
<style scoped>
.title_div_class {
    height: 1.0625rem;
    border: .035rem solid #A57A76;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%;
    margin: 0 auto;
    flex-wrap: wrap;
}

.title_div_class_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title_div_class_row_item {
    color: #A57A76;
    font-size: .305rem;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.title_div_class_row_item_text {
    padding-left: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.crile_class {
    width: .225rem;
    height: .225rem;
    border-radius: 100%;
    background: #999999;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    margin-right: .0625rem;
}

.ji_qi_type_class {
    width: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
</style>